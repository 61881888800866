import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { API_URL } from "./constants";

// Function to get the appropriate question based on the feedback reason
const getQuestionForReason = (reason) => {
  switch (reason) {
    case "no_need":
      return "We understand you may not need data at the moment. Could you tell us more about what would make our platform more useful for you?";
    case "format_issue":
      return "Please mention which format of data you would like to generate.(Image, Audio, Video, Numeric, Text)";
    case "pricing":
      return "Please mention what pricing would work for you.";
    case "technical_issues":
      return "Please elaborate on the issue you have faced.";
    case "interface":
      return "Please mention the specific aspect of UI that you found difficult to navigate.";
    default:
      return "We’d love to know why you haven’t generated any data yet. What can we do to help you get started?";
  }
};

const FeedbackPage = () => {
  const [reason, setReason] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Extract query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    setReason(queryParams.get("reason"));
    setUserEmail(queryParams.get("email")); // Get email from URL
  }, [location]);

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = async () => {
    if (!feedback.trim()) return;
  
    const feedbackData = {
      email: userEmail,
      reason: reason,
      feedback: feedback,
    };
  
    try {
      const response = await fetch(`${API_URL}/api/email-feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(feedbackData),
      });
  
      if (!response.ok) {
        throw new Error("Failed to submit feedback");
      }
  
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };  

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
        <h2 className="text-2xl font-bold mb-4">We'd Love Your Feedback!</h2>
        <p className="text-gray-600">Please share your thoughts with us.</p>

        {reason && (
          <div>
            <h3 className="text-xl font-semibold my-4">{getQuestionForReason(reason)}</h3>
            <textarea
              className="w-full p-3 border border-gray-300 rounded-md mb-4"
              rows="4"
              placeholder="Your feedback..."
              value={feedback}
              onChange={handleFeedbackChange}
            ></textarea>
            <button
              className={`bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                feedback.trim() === "" ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleSubmit}
              disabled={feedback.trim() === ""}
            >
              Submit Feedback
            </button>

            {submitted && (
              <div className="mt-4 text-green-500 font-semibold">
                Thank you for your feedback! 🙌
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackPage;