import { useState } from "react";

const ColumnPopup = ({ isOpen, onClose, onSave }) => {
//   const [isOpen, setIsOpen] = useState(false);
  console.log("Received Props:", { isOpen, onClose, onSave });
  const [columns, setColumns] = useState([]);

  const handleAddRow = () => {
    setColumns([
      ...columns,
      { columnName: "", type: "Text", allowDuplicates: "Yes" },
    ]);
  };

  const handleChange = (index, field, value) => {
    const updatedColumns = [...columns];
    updatedColumns[index][field] = value;
    setColumns(updatedColumns);
  };

  const handleSave = () => {
    onSave(columns);
    // setIsOpen(false);
  };

  return (
    <>
      {/* Open Popup Button */}
      {/* <button
        onClick={() => setIsOpen(true)}
        className="px-4 py-2 bg-blue-600 text-white rounded-lg"
      >
        Open Popup
      </button> */}

      {/* Modal */}
      {isOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-lg font-semibold mb-4">Add Columns</h2>

            {/* Table for Inputs */}
            <div className="space-y-2">
              {columns.map((col, index) => (
                <div key={index} className="flex space-x-2">
                  <input
                    type="text"
                    placeholder="Column Name"
                    value={col.columnName}
                    onChange={(e) =>
                      handleChange(index, "columnName", e.target.value)
                    }
                    className="border p-2 w-1/3 rounded"
                  />
                  <select
                    value={col.type}
                    onChange={(e) => handleChange(index, "type", e.target.value)}
                    className="border p-2 w-1/3 rounded"
                  >
                    <option value="Text">Text</option>
                    <option value="Numeric">Numeric</option>
                  </select>
                  <select
                    value={col.allowDuplicates}
                    onChange={(e) =>
                      handleChange(index, "allowDuplicates", e.target.value)
                    }
                    className="border p-2 w-1/3 rounded"
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              ))}
            </div>

            {/* Buttons */}
            <div className="mt-4 flex justify-between">
              <button
                onClick={handleAddRow}
                className="bg-green-500 text-white px-3 py-1 rounded"
              >
                + Add Row
              </button>
              <div>
                <button
                  onClick={handleSave}
                  className="bg-blue-500 text-white px-4 py-1 rounded mr-2"
                >
                  Save
                </button>
                <button
                  onClick={onClose}
                  className="bg-gray-500 text-white px-4 py-1 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ColumnPopup;