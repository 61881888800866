import React, { useState } from 'react';
// import { toast, ToastContainer } from 'react-toastify';
import { API_URL } from './constants';

const PopupExample = ({ isOpen, setIsOpen, pro, toast }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const togglePopup = () => {
    setIsOpen(!isOpen);
    setName("");
    setEmail("");
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      const params = {
        "email_address": email,
        "user_name": name,
        "pro_or_enterprise": pro
      };
      const response = await fetch(`${API_URL}/api/join-waitlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      toast.success("Thank you for joining the waiting list. You will soon receive an email with further steps.");
    } catch (error) {
      console.error('Error calling API:', error);
    }
    setIsOpen(false);
    setName("");
    setEmail("");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>
      <div className="flex min-h-full items-center justify-center p-4">
        <div className="relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all w-full max-w-sm">
          <h2 className="text-xl font-semibold text-center mb-4">Contact Us</h2>
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-gray-700 font-semibold mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="Enter your name"
                required
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-gray-700 font-semibold mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="flex justify-between items-center pt-4">
              <button
                type="button"
                onClick={togglePopup}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
              >
                Close
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupExample;