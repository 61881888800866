import React, {useEffect} from 'react';
import { Grid, Typography, Button, Box, Card, Fade } from '@mui/material';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";

const GridComponent = ({rowData, colDefs}) => {

  useEffect(() => {
    const disableRightClick = (event) => event.preventDefault();
    // const disableCopyPaste = (event) => event.preventDefault();
    // const disableDrag = (event) => event.preventDefault();

    document.addEventListener("contextmenu", disableRightClick);
    // document.addEventListener("copy", disableCopyPaste);
    // document.addEventListener("cut", disableCopyPaste);
    // document.addEventListener("paste", disableCopyPaste);
    // document.addEventListener("dragstart", disableDrag);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      // document.removeEventListener("copy", disableCopyPaste);
      // document.removeEventListener("cut", disableCopyPaste);
      // document.removeEventListener("paste", disableCopyPaste);
      // document.removeEventListener("dragstart", disableDrag);
    };
  }, []);

  const pagination = true;

  // sets 10 rows per page (default is 100)
  const paginationPageSize = 10;

  // allows the user to select the page size from a predefined list of page sizes
  const paginationPageSizeSelector = [10, 20, 30, 50];
  
  return (
      <div
      className="ag-theme-quartz" // applying the grid theme
      style={{ height: 400 }} // the grid will fill the size of the parent container
    >
      <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
      />
    </div>
  // </div>
  );
};

export default GridComponent;