// import { createContext, useContext, useState } from "react";

// const UserDetailsContext = createContext();

// export const UserDetailsProvider = ({ children }) => {
//     const [userDetails, setUserDetails] = useState(null); // Only stores additional details

//     return (
//         <UserDetailsContext.Provider value={{ userDetails, setUserDetails }}>
//             {children}
//         </UserDetailsContext.Provider>
//     );
// };

// // Hook to use user details anywhere in the app
// export const useUserDetails = () => useContext(UserDetailsContext);

// -------------------------------------------- LATEST CODE --------------------------------------------
// import { createContext, useContext, useState, useEffect } from "react";

// const UserDetailsContext = createContext(null);

// export const UserDetailsProvider = ({ children }) => {
//     const [userDetails, setUserDetails] = useState(() => {
//         // Load userDetails from localStorage on initial render
//         const storedDetails = localStorage.getItem("userDetails");
//         return storedDetails ? JSON.parse(storedDetails) : null;
//     });

//     // Update localStorage whenever userDetails changes
//     useEffect(() => {
//         if (userDetails) {
//             localStorage.setItem("userDetails", JSON.stringify(userDetails));
//         } else {
//             localStorage.removeItem("userDetails");
//         }
//     }, [userDetails]);

//     return (
//         <UserDetailsContext.Provider value={{ userDetails, setUserDetails }}>
//             {children}
//         </UserDetailsContext.Provider>
//     );
// };

// export const useUserDetails = () => {
//     const context = useContext(UserDetailsContext);
//     if (!context) {
//         throw new Error("useUserDetails must be used within a UserDetailsProvider");
//     }
//     return context;
// };

import { createContext, useContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const UserDetailsContext = createContext(null);

export const UserDetailsProvider = ({ children }) => {
    const [userDetails, setUserDetails] = useState(() => {
        // Load userDetails from localStorage on initial render
        const storedDetails = localStorage.getItem("userDetails");
        return storedDetails ? JSON.parse(storedDetails) : null;
    });

    const [token, setToken] = useState(null);

    // Update localStorage whenever userDetails changes
    useEffect(() => {
        if (userDetails) {
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
        } else {
            localStorage.removeItem("userDetails");
        }
    }, [userDetails]);

    // Listen for Firebase authentication changes and update the token
    useEffect(() => {
        const auth = getAuth();
        return onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                const idToken = await firebaseUser.getIdToken();
                setToken(idToken);
            } else {
                setToken(null);
            }
        });
    }, []);

    return (
        <UserDetailsContext.Provider value={{ userDetails, setUserDetails, token }}>
            {children}
        </UserDetailsContext.Provider>
    );
};

export const useUserDetails = () => {
    const context = useContext(UserDetailsContext);
    if (!context) {
        throw new Error("useUserDetails must be used within a UserDetailsProvider");
    }
    return context;
};


