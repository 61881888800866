import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { API_URL } from './constants';
import { useUserDetails } from "./UserDetailsContext";

const EntryRow = ({ entry }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const {
    prompt,
    purpose,
    timestamp,
    language,
    nowOrLater,
    col_names,
    number,
    filename
  } = entry;


  const previewFields = (
    <div className="grid grid-cols-3 gap-4 w-full">
      <div className="truncate">{prompt || '-'}</div>
      <div className="truncate">{purpose || '-'}</div>
      <div>  {timestamp
      ? new Date(timestamp).toISOString().slice(0, 19).replace("T", " ")
      : '-'}</div>
    </div>
  );

  const expandedDetails = (
    <div className="mt-4 pl-8 space-y-3 text-sm">
      <div className="grid grid-cols-2 gap-x-4 gap-y-2">
        {/* <div>
          <span className="font-medium text-gray-500">:</span>
          <span className="ml-2">{ || '-'}</span>
        </div> */}
        <div>
          <span className="font-medium text-gray-500">Timing:</span>
          <span className="ml-2">
            {timestamp
              ? new Date(timestamp).toISOString().slice(0, 19).replace("T", " ")
              : '-'}
          </span>
        </div>
        <div>
          <span className="font-medium text-gray-500">Number:</span>
          <span className="ml-2">{number || '-'}</span>
        </div>
        <div className="col-span-2">
          <span className="font-medium text-gray-500">Column Names:</span>
          <span className="ml-2">{col_names || '-'}</span>
        </div>
        <div className="col-span-2">
          <span className="font-medium text-gray-500">Full Prompt:</span>
          <p className="mt-1 whitespace-pre-wrap">{prompt || '-'}</p>
        </div>
        {/* Add the download link here */}
        {/* {filename && (
          <div className="col-span-2">
            <a
              href={`${API_URL}/api/download?filename=${encodeURIComponent(filename)}`}
              className="text-blue-500 underline hover:text-blue-700"
              download
            >
              Download File
            </a>
          </div>
        )} */}
      </div>
    </div>
  );

  return (
    <div className="border-b border-gray-200 hover:bg-gray-50">
      <div
        className="py-4 px-6 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex gap-2">
          {isExpanded ? (
            <ChevronDown className="w-5 h-5 text-gray-400 shrink-0 mt-1.5" />
          ) : (
            <ChevronRight className="w-5 h-5 text-gray-400 shrink-0 mt-1.5" />
          )}
          {previewFields}
        </div>
        {isExpanded && expandedDetails}
      </div>
    </div>
  );
};

const LoadingRow = () => (
  <div className="border-b border-gray-200">
    <div className="py-4 px-6">
      <div className="grid grid-cols-3 gap-4 w-full">
        <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
        <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
        <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
      </div>
    </div>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="p-4 text-center text-red-600">
    <p>{message}</p>
  </div>
);

const EntryTable = ({user}) => {
  const [entries, setEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userDetails, setUserDetails, token } = useUserDetails();

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
      const response = await fetch(`${API_URL}/api/user-activity`,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({"user_id": user.uid})
      }); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch entries');
        }
        const data = await response.json();
        setEntries(data["user_activities"]);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEntries();
  }, []);

  return (
    <div className="p-10 bg-white rounded-lg shadow">
      {/* Header */}
      <div className="px-6 py-3 border-b border-gray-200 bg-gray-50">
        <div className="grid grid-cols-3 gap-4 text-sm font-medium text-gray-500">
          <div>Prompt</div>
          <div>Purpose</div>
          <div>Date/Time</div>
        </div>
      </div>
      
      {/* Content */}
      <div className="p-6 divide-y divide-gray-200">
        {isLoading ? (
          // Loading state
          <>
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
          </>
        ) : error ? (
          // Error state
          <ErrorMessage message={error} />
        ) : entries.length === 0 ? (
          // Empty state
          <div className="p-4 text-center text-gray-500">
            No entries found
          </div>
        ) : (
          // Data display
          entries.map((entry, index) => (
            <EntryRow key={index} entry={entry} />
          ))
        )}
      </div>
    </div>
  );
};

export default EntryTable;