// import { useEffect } from "react";
// import { API_URL } from "./constants";
// import { useUserDetails } from "./UserDetailsContext";

// const RazorpayCheckout = () => {
//   const { userDetails, setUserDetails, token } = useUserDetails();

//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = "https://checkout.razorpay.com/v1/checkout.js";
//     script.async = true;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   const createOrder = async () => {
//     const response = await fetch(`${API_URL}/api/create-order`, {
//       method: "POST",
//       headers: {"Content-Type": "application/json", "Authorization": `Bearer ${token}`},
//       body: JSON.stringify({ amount: 5, currency: "USD" })
//     });

//     const data = await response.json();
//     launchRazorpay(data.id);
//   };

//   const launchRazorpay = (orderId) => {
//     if (!window.Razorpay) {
//       alert("Razorpay SDK failed to load. Check your internet connection.");
//       return;
//     }

//     const options = {
//       key: process.env.PAYMENT_KEY_ID, // Replace with your actual Razorpay Key ID
//       amount: 500, // Razorpay uses paisa (cents)
//       currency: "USD",
//       name: "DataCreator AI",
//       order_id: orderId,
//       handler: async function (response) {
//         // console.log("Payment successful:", response);
//         // response['user_id'] = userDetails.user_id
//         // Send data to backend
//         const res = await fetch(`${API_URL}/api/verify-payment`, {
//           method: "POST",
//           headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`},
//           body: JSON.stringify(response)
//         });
  
//         const data = await res.json();
//         if (data.success) {
//           setUserDetails({ ...userDetails, credits: data.credits });
//           alert("Your payment has been successful. Please proceed with your data generation.")
//         } else {
//           alert("Your payment has failed. Please try after some time or contact us by clicking on the chat icon.");
//         }
//       },
//       prefill:  {
//         name: null,
//         email: null,
//         contact: null
//       },
//       modal: {
//         ondismiss: function() {
//           alert("Payment was closed before completing.");
//         }
//       }
//     };

//     const rzp = new window.Razorpay(options);
//     rzp.open();
//   };

//   return <button
//     className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition-all duration-200"
//     onClick={createOrder}
//   > 
//   Purchase Credits
//   </button>
  
// };

// export default RazorpayCheckout;

// ---------------------------------------- NEW CODE --------------------------------------------

import { useEffect, useState } from "react";
import { API_URL } from "./constants";
import { useUserDetails } from "./UserDetailsContext";

const RazorpayCheckout = () => {
  const { userDetails, setUserDetails, token } = useUserDetails();
  const [showPopup, setShowPopup] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const createOrder = async () => {
    if (!agreeToTerms) {
      alert("Please agree to the Refund Policy before proceeding.");
      return;
    }

    setLoadingOrder(true);

    try {
      const response = await fetch(`${API_URL}/api/create-order`, {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
        body: JSON.stringify({ amount: 5, currency: "USD" }),
      });

      const data = await response.json();
      setLoadingOrder(false);
      launchRazorpay(data.id);
    } catch (error) {
      setLoadingOrder(false);
      console.log(error);
      alert("Error creating order. Please try again later.");
    }
  };

  const launchRazorpay = (orderId) => {
    if (!window.Razorpay) {
      alert("Razorpay SDK failed to load. Check your internet connection.");
      return;
    }

    setLoadingPayment(true);

    const options = {
      key: process.env.PAYMENT_KEY_ID, // Replace with your actual Razorpay Key ID
      amount: 500, // Razorpay uses paisa (cents)
      currency: "USD",
      name: "DataCreator AI",
      order_id: orderId,
      handler: async function (response) {
        try {
          const res = await fetch(`${API_URL}/api/verify-payment`, {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            body: JSON.stringify(response),
          });

          const data = await res.json();
          setLoadingPayment(false);

          if (data.success) {
            setUserDetails({ ...userDetails, credits: data.credits });
            alert("Your payment has been successful. Please proceed with your data generation.");
          } else {
            alert("Your payment has failed. Please try again later or contact us.");
          }
        } catch (error) {
          setLoadingPayment(false);
          alert("Error verifying payment. Please contact support.");
        }
      },
      prefill: {
        name: null,
        email: null,
        contact: null,
      },
      modal: {
        ondismiss: function () {
          setLoadingPayment(false);
          alert("Payment was closed before completing.");
        },
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  return (
    <>
      <button
        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition-all duration-200 flex items-center justify-center"
        onClick={() => setShowPopup(true)}
        disabled={loadingOrder || loadingPayment}
      >
        {loadingOrder || loadingPayment ? (
          <span className="animate-spin border-4 border-white border-t-transparent rounded-full w-5 h-5 mr-2"></span>
        ) : null}
        Purchase Credits
      </button>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm">
            <h2 className="text-lg font-semibold mb-4">No Refund Policy</h2>
            <p className="text-gray-700 mb-4">
              All credit purchases are final and non-refundable. We do not offer cancellations or exchanges as per our policy.
            </p>
            <label className="flex items-center text-sm text-gray-600 mb-4">
              <input
                type="checkbox"
                className="mr-2"
                checked={agreeToTerms}
                onChange={() => setAgreeToTerms(!agreeToTerms)}
              />
              I understand that all credit purchases are final.
            </label>
            <div className="flex justify-end">
              <button
                onClick={() => setShowPopup(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setShowPopup(false);
                  createOrder();
                }}
                className={`ml-2 px-4 py-2 rounded ${
                  agreeToTerms ? "bg-green-600 hover:bg-green-700 text-white" : "bg-gray-300 cursor-not-allowed"
                }`}
                disabled={!agreeToTerms}
              >
                Agree & Buy
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RazorpayCheckout;
