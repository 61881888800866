import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../firebase-auth';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import PromoBanner from './PromoBanner';

const Header = ({user}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profilePic, setProfilePic] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  
  const hideAppBar = location.pathname === '/signin' || location.pathname === '/terms-and-privacy';

  useEffect(() => {
    if(user !== null) {
      setIsLoggedIn(true);
      setProfilePic(user.photoURL == null ? "https://fastly.picsum.photos/id/168/200/200.jpg?hmac=VxnpUGg87Q47YRONmdsU2vNGSPjCs5vrwiAL-0hEIHM" : user.photoURL);
      setUsername(user.displayName == null ? "User" : user.displayName);
    };
  },[user]);

  const handleBlogClick = () => {
    navigate("/blog");
  };

  const handleDatasetClick = () => {
    navigate("/cdatasets");
  };

  const handleCreditClick = () => {
    navigate("/creditinfo");
  };

  const handlePromptClick = () => {
    navigate("/prompthistory");
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);
      user = null;
      setShowDropdown(false);
    } catch (error) {
      console.error("Error during logout: ", error);
    }
  };

  return (
    !hideAppBar && (
      <>
        <PromoBanner />
        <header className="flex items-center justify-between px-8 py-4 shadow-md bg-white">
          <Link to="/" className="text-3xl font-bold text-blue-700 hover:text-blue-900 transition-colors duration-300">
            DataCreator AI
          </Link>

          <div className="flex items-center space-x-4">
            <button onClick={handleBlogClick} className="bg-blue-500 text-white px-5 py-2 rounded-md shadow hover:bg-blue-600 transition duration-300">
              BLOG
            </button>

            <button onClick={handleDatasetClick} className="bg-blue-500 text-white px-5 py-2 rounded-md shadow hover:bg-blue-600 transition duration-300">
              Community Datasets
            </button>
            
            {isLoggedIn ? (
              <div className="relative">
                <img
                  src={profilePic || "https://via.placeholder.com/40"}
                  alt="User Profile"
                  referrerPolicy="no-referrer"
                  className="w-10 h-10 rounded-full border-2 border-blue-600 cursor-pointer"
                  onClick={() => setShowDropdown(!showDropdown)}
                />

                {showDropdown && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg z-50">
                    <div className="p-2 text-gray-800">Hello, {username}</div>
                    <hr />
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-100"
                      onClick={handleCreditClick}
                    >
                      Credit Information
                    </button>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-100"
                      onClick={handlePromptClick}
                    >
                      Data History
                    </button>
                    <button
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-blue-100"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <button
                className="bg-blue-500 text-white px-5 py-2 rounded-full shadow hover:bg-blue-600 transition duration-300"
                onClick={() => {
                  navigate("/signin");
                }}
              >
                Sign In
              </button>
            )}
          </div>
        </header>
      </>
    )
  );
};

export default Header;