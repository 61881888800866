import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import PolicyPoints from './PolicyPoints';
import { Checkbox, Typography, Link } from '@mui/material';
import SignupPageLeftSide from './SignupPageLeftSide';
import { API_URL, SIGNIN_BENEFITS } from './constants';
import { auth, provider} from "../firebase-auth";
import { signInWithPopup, setPersistence, browserSessionPersistence } from "firebase/auth";
// import {useNavigate} from 'react-router-dom';
import Auth from './Auth';

const SignupPageClaude = ({setUser}) => {
  const [currentBenefitIndex, setCurrentBenefitIndex] = useState(0);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  // const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  // const signInWithGoogle = () => {
  //   signInWithPopup(auth, provider)
  //     .then((result) => {
  //       setUser(result.user);
  //       manageUser(result.user);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBenefitIndex((prev) => (prev + 1) % SIGNIN_BENEFITS.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-[linear-gradient(135deg,#EEF2FF_0%,#E0E7FF_100%)] relative overflow-hidden">
      {/* Background texture */}
      <div 
        className="absolute inset-0 opacity-40"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%234F46E5' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          backgroundSize: '32px 32px'
        }}
      />

      <div className="container mx-auto px-8 py-12 relative">
        <div className="flex flex-col lg:flex-row items-start justify-between gap-16">
          {/* Left side - Benefits */}
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="hidden lg:flex flex-col flex-1"
          >
            <h1 className="text-5xl font-bold text-blue-900 mb-4">Welcome to DataCreator AI!</h1>
            <div className="mt-2">
              <PolicyPoints />
            </div>
          </motion.div>

          {/* Right side - Sign up form */}
          <motion.div 
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="w-full lg:w-[480px] bg-white rounded-2xl shadow-xl p-12"
          >
            <div className="space-y-3">
              <h2 className="text-3xl font-semibold text-center text-blue-900">
                Join DataCreator AI Now!
              </h2>

                <div>
                    {/* 
                      className="block lg:hidden h-10 flex"
                      {SIGNIN_BENEFITS.map((benefit, index) => (
                        <motion.div
                        key={index}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: currentBenefitIndex === index ? 1 : 0 }}
                        transition={{ duration: 0.4 }}
                        className="absolute text-lg text-blue-700 font-medium"
                        >
                        {benefit}
                        </motion.div>
                    ))} */}
                    {/* <PolicyPoints /> */}
                    {/* <h3 className="font-semibold text-gray-800">Specify your requirements and generate your dataset.</h3> */}
                </div>

              

              <div className="flex items-center">
                {/* <Checkbox
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                    inputProps={{ 'aria-label': 'Agree to Terms and Conditions' }}
                />
                <Typography variant="body2">
                    I agree to the{' '}
                    <Link
                        href="/terms-and-privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms and Conditions
                    </Link>
                </Typography> */}
                {/* <input
                  type="checkbox"
                  id="terms"
                  className="mr-3 w-4 h-4"
                  onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                />
                <label htmlFor="terms" className="text-sm text-gray-600">
                  I agree to the Terms and Conditions
                </label> */}
              </div>

              <SignupPageLeftSide />

              {/* <a href="https://datacreatorai.com/pricing" target="_blank">Check out our Pricing</a> */}
              
              <Auth setUser={setUser} />

              <div className="space-y-4">
                {/* <button
                  className="w-full flex items-center justify-center gap-3 px-6 py-3 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                  disabled={!isCheckboxChecked}
                  onClick={signInWithGoogle}
                >
                  <img src="google_logo.png" alt="Google" className="w-6 h-6" />
                  Sign in with Google
                </button> */}

            {/* <SignupPageLeftSide /> */}


                {/* <button
                  className="w-full flex items-center justify-center gap-3 px-6 py-3 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
                  disabled={!isCheckboxChecked}
                >
                  <img src="github_logo.png" alt="GitHub" className="w-6 h-6" />
                  Sign in with GitHub
                </button>

                <button
                  className="w-full flex items-center justify-center gap-3 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  disabled={!isCheckboxChecked}
                >
                  <img src="microsoft_logo.png" alt="Microsoft" className="w-6 h-6" />
                  Sign in with Microsoft
                </button> */}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SignupPageClaude;