import { useState } from "react";
import { auth, provider } from "../firebase-auth"; // Ensure correct Firebase config
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, 
    signInWithPopup, GoogleAuthProvider, sendEmailVerification, sendPasswordResetEmail, 
    setPersistence, browserSessionPersistence } 
    from "firebase/auth";
import {useNavigate} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { API_URL } from "./constants";
import { useUserDetails } from "./UserDetailsContext";

const Auth = ({setUser}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { userDetails, setUserDetails } = useUserDetails();

  const handleForgotPassword = async () => {
    if (!email) {
      setError("Please enter your email to reset password.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset link sent! Check your email.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
    }
  };

const manageUser = async (user) => {
    const formData = new FormData();
    formData.append('user_id', user.uid);
    formData.append('display_name', user.displayName === null ? "User" : user.displayName);
    formData.append('email', user.email);
    formData.append('phone_number', user.phoneNumber);
    formData.append('photo_url', user.photoURL);
    formData.append('account_creation_time', user.metadata.creationTime);
    formData.append('last_login_time', user.metadata.lastSignInTime);

    try {
        const response = await fetch(`${API_URL}/api/manage-user`, {
          method: 'POST',
          body: formData
        });

        const userResponse = await response.json();
        return (userResponse.user_details);
        
    } catch (error) {
        console.log('Error Adding/Updating user.');
    }
};
  
  // Handle Email/Password Auth
  const handleAuth = async (e) => {
    e.preventDefault();
    // await disableButtons();
    try {
        if(!agreed)
        {
            setError("Please read and agree to the Terms and Conditions!");
            return;
        }
      if (isSignUp) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        // console.log(user);
        await auth.signOut();
        setUser(null);
        navigate("/signin");
        await sendEmailVerification(user); // Send verification email
        toast.success("Verification email sent! Please check your inbox.");
        // navigate("/"); // Redirect to a protected page
    
        // // Optional: Sign out the user after registration to prevent unverified access
        // alert("Please verify your email before signing in.");
      } else {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        // console.log(user);
        if (!user.emailVerified) {
            toast.error("Please verify your email address before login.");
            await auth.signOut(); // Logs out unverified users
            setUser(null);
            navigate("/signin");
            return;
        }
        
        setUser(user);
        var userDetails = await manageUser(user);
        setUserDetails(userDetails);
      }
    } catch (err) {
      // setError(err.message);
      toast.error("Something went wrong! Please check your credentials.");
    }
  };

  // Handle Social Auth
//   const handleSocialSignIn = async (provider) => {
//     try {
//       if(!agreed){
//         setError("Please read and agree to the Terms and Conditions!");
//         return;
//       }
//       await signInWithPopup(auth, provider);
//     } catch (err) {
//       setError(err.message);
//     }
//   };

    // const signInWithGoogle = () => {
       
    //     signInWithPopup(auth, provider)
    //     .then((result) => {
    //         setUser(result.user);
    //         manageUser(result.user);
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //     });
    // };

    const signInWithGoogle = () => {
      if(!agreed)
        {
            setError("Please read and accept the Terms and Conditions.");
            return;
        }
      setPersistence(auth, browserSessionPersistence)
        .then(() => {
          return signInWithPopup(auth, provider);
        })
        .then(async (result) => {
          // User is signed in
          const user = result.user;
          setUser(user);
          const userDetails = await manageUser(user); // Your additional user management logic
          setUserDetails(userDetails);
        })
        .catch((error) => {
          console.error(error); // Handle errors (e.g., sign-in error)
        });
    };


  return (
    <div className="flex flex-col items-center bg-white p-7 rounded-lg shadow-lg w-full max-w-lg">
      {/* <h2 className="text-3xl font-bold text-blue-600 mb-4">{isSignUp ? "Sign Up" : "Sign In"}</h2> */}

      {/* Social Login Buttons */}
      <div className="flex justify-center gap-4 w-full flex-wrap">
        {/* <button
            onClick={() => handleSocialSignIn(provider)}
            className="flex items-center justify-center w-[230px] bg-white border border-gray-300 p-2 rounded-lg hover:bg-gray-100 transition text-sm whitespace-nowrap"
        >
            <FcGoogle className="text-lg mr-2" /> Sign in with Google
        </button> */}
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <button
                  className="w-full flex items-center justify-center gap-3 px-6 py-3 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                //   disabled={!agreed}
                  onClick={signInWithGoogle}
        >
            <img src="google_logo.png" alt="Google" className="w-6 h-6" />
            Sign in with Google
        </button> 
        {/* <button
            onClick={() => handleSocialSignIn(provider)}
            className="flex items-center justify-center w-[230px] bg-black text-white p-2 rounded-lg hover:bg-gray-800 transition text-sm whitespace-nowrap"
        >
            <FaGithub className="text-lg mr-2" /> Sign in with GitHub
        </button> */}
    </div>

      <div className="w-full border-t my-6"></div> {/* Divider */}

      {/* Email/Password Login */}
      <form onSubmit={handleAuth} className="w-full flex flex-col gap-4">
        <input
          type="email"
          placeholder="Enter your email"
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Enter your password"
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        {/* Terms and Conditions */}
        <div className="flex items-center gap-2">
          <input type="checkbox" checked={agreed} onChange={() => setAgreed(!agreed)} className="w-4 h-4" />
          <label className="text-gray-600 text-sm">
            I agree to the <a href="/terms-and-privacy" className="text-blue-500 hover:underline">Terms and Conditions</a>
          </label>
        </div>

        {/* Submit Button */}
        <button type="submit" className="w-full bg-blue-600 text-white p-3 rounded-lg font-semibold hover:bg-blue-700 transition">
          {isSignUp ? "Sign Up" : "Sign In"}
        </button>
      </form>

      <p className="mt-4 text-gray-600 text-sm">
        {isSignUp ? "Already have an account?" : "Don't have an account?"}
        <button className="text-blue-500 font-semibold ml-1" onClick={() => setIsSignUp(!isSignUp)}>
          {isSignUp ? "Sign In" : "Sign Up"}
        </button>
      </p>
      <div className="forgot-password">
            <a href="#" className = "text-blue-500 text-sm" onClick={handleForgotPassword}>Forgot Password?</a>
      </div>
       <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

export default Auth;