import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  IconButton, Tooltip
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const PopupFormComponent = ({ open, onClose, onSubmit, evaluation_instructions }) => {
  const [textValue, setTextValue] = useState('');
  evaluation_instructions = "";
  const [radioValue, setRadioValue] = useState('human');

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleSubmit = () => {
    evaluation_instructions = textValue;
    onSubmit("feedback", evaluation_instructions);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}
    PaperProps={{
        sx: {
          width: '80%',
          maxWidth: 700
        }
      }}
    >
      <DialogTitle sx = {{color: 'blue', display: 'flex', alignItems: 'center'}}> Add Your Feedback
        <Tooltip title="Here, you can specify requirements for dataset updates. You can mention corrections, tone adjustments, or any other feedback. We will use your feedback to improve the generated data.">
            <IconButton
            aria-label="help"
            sx={{ ml: 1, color: 'black' }}
            >
            <HelpOutlineIcon />
            </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        {/* <Box sx={{ mb: 2 }}>
          <RadioGroup
            aria-label="evaluation-options"
            name="evaluation-options"
            value={radioValue}
            onChange={handleRadioChange}
            row
          >
            <FormControlLabel value="ai" control={<Radio />} label="AI Evaluation" />
            <FormControlLabel value="human" control={<Radio />} label="Human Evaluation" />
          </RadioGroup>
        </Box> */}
        <TextField
          autoFocus
          margin="dense"
          InputLabelProps={{
            style: { whiteSpace: 'normal', maxWidth: '100%' },
          }}
          id="evaluation-text"
          label="Please provide feedback on the generated data and if it matches your expectations."
          type="text"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={textValue}
          onChange={handleTextChange}
        />
      </DialogContent>
      <DialogActions>
        <Button sx = {{color: 'blue'}} onClick={onClose}>Cancel</Button>
        <Button sx = {{color: 'blue'}} onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupFormComponent;
