// import { useState } from "react";

// export default function ConfirmDownload({ open, onProceed, onCancel }) {
// //   const [open, setOpen] = useState(true);

//   const handleProceed = () => {
//     onProceed();
//   };

//   const handleCancel = () => {
//     onCancel();
//   };

// //   const handleRequestReview = () => {
// //     setOpen(false);
// //     onRequestReview();
// //   };

//   if (!open) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="bg-white w-11/12 max-w-md p-6 rounded-2xl shadow-lg">
//         <h2 className="text-xl font-semibold text-gray-900">Download Confirmation</h2>
//         <p className="text-gray-700 mt-2">
//           You will receive a payment link to your registered email address to complete the payment. 
//           Once successful, your file will be sent to you via email. Don't worry, this process happens quickly. 🚀
//         </p>

//         <div className="mt-4 border-t pt-4">
//           <p className="font-semibold">Starter Download Pricing:</p>
//           <ul className="list-disc pl-6 text-gray-700">
//             <li>500 data points: <span className="font-medium">$2</span></li>
//             <li>Proportional pricing for lesser data points</li>
//             <li className="text-red-500">Human review not included</li>
//           </ul>
//         </div>

//         <p className="mt-4 text-gray-700">
//           For a human expert review, click on <strong>Request Review</strong>.
//         </p>

//         <div className="mt-6 flex justify-end gap-3">
//           <button className="px-4 py-2 text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300" onClick={handleCancel}>
//             Cancel
//           </button>
//           <button className="px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600" onClick = {handleProceed}>
//             Proceed
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }
import { useState } from "react";
import RazorPayCheckOut from './RazorPayCheckOut'

export default function ConfirmDownload({ open, credits, onCancel, onProceed }) {
  // const [open, setOpen] = useState(false);

  const handleProceed = () => {
    if (credits > 0) {
      onProceed();
    } else {
      // setOpen(true); // Show popup only if credits are insufficient
    }
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        {/* <p className="text-gray-900 font-semibold">Available Credits: {credits}</p> */}
        {/* <button
          className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
          // onClick={onPurchase}
        >
          Purchase Credits
        </button> */}
      </div>

      {/* <button
        className="mt-4 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
        onClick={handleProceed}
      >
        Download File
      </button> */}

      {open && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-11/12 max-w-md p-6 rounded-2xl shadow-lg">
            <h2 className="text-xl font-semibold text-gray-900">Insufficient Credits</h2>
            <p className="text-gray-700 mt-2">
              You don’t have enough credits to proceed with the download. Please purchase more credits.
            </p>
            <div className="mt-6 flex justify-end gap-3">
              <button
                className="px-4 py-2 text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300"
                onClick={handleClose}
              >
                Close
              </button>
              <RazorPayCheckOut />
              {/* <button
                className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                onClick={onPurchase}
              >
                Purchase Credits
              </button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}