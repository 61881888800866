import React, { useState } from 'react';
import RazorpayButton from './RazorpayButton';
import PaymentStatus from './PaymentStatus';
import RazorpayCheckout from './RazorPayCheckOut';
import { useUserDetails } from "./UserDetailsContext";

const CreditCalculator = () => {
  const [dollars, setDollars] = useState('');
  const [calculatedCredits, setCalculatedCredits] = useState(0);
  const { userDetails, setUserDetails } = useUserDetails();
  
  // Mock data - replace with actual data from your backend
  const [availableCredits, setAvailableCredits] = useState(0); // Starting with 1000 free credits
  const [billingHistory] = useState([
    // {
    //   id: 1,
    //   date: '2025-02-20',
    //   amount: 10.00,
    //   credits: 2000,
    //   status: 'completed'
    // },
    // {
    //   id: 2,
    //   date: '2025-02-15',
    //   amount: 25.00,
    //   credits: 3500,
    //   status: 'completed'
    // },
    // {
    //   id: 3,
    //   date: '2025-02-10',
    //   amount: 5.00,
    //   credits: 1500,
    //   status: 'completed'
    // }
  ]);

  // Conversion rates
  const MIN_DOLLARS = 5;
  const CREDITS_PER_5_DOLLARS = 500;
  const FREE_CREDITS = 1000;
  
  const calculateCredits = (dollarAmount) => {
    const amount = parseFloat(dollarAmount) || 0;
    if (amount < MIN_DOLLARS) return 0;
    return Math.floor((amount / MIN_DOLLARS) * CREDITS_PER_5_DOLLARS);
  };

  const setCredits = (amount) => {
    setCalculatedCredits(calculateCredits(amount));
  }
  
  const handleDollarChange = (e) => {
    const value = e.target.value;
    setDollars(value);
    setCalculatedCredits(calculateCredits(value));
  };

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6 p-4">
      {/* Credit Balance Card */}
      {/* <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-800">Available Credits</h2>
          <div className="mt-2 text-3xl font-bold text-gray-900">{availableCredits} credits</div>
          <div className="mt-2 text-sm text-gray-600">Including your 1000 free credits!</div>
        </div>
      </div> */}

      {/* Purchase Credits Card */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-800">Purchase Credits</h2>
          <div className="mt-4 space-y-4">
            {/* <div className="space-y-2">
              <label htmlFor="dollars" className="block text-sm font-medium text-gray-700">
                Amount in USD (Minimum $5)
              </label>
              <input
                id="dollars"
                type="number"
                min={MIN_DOLLARS}
                step="0.01"
                value={dollars}
                onChange={handleDollarChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter amount in USD"
              />
            </div> */}
            
            <div className="bg-gray-50 rounded-md p-4">
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium text-gray-700">Available Credits:</span>
                <span className="text-lg font-bold text-gray-900">
                  {userDetails?.credits > 0 ? userDetails.credits : 0} credits
                </span>
              </div>
              <div className="mt-2 text-xs text-gray-500">
                Rate: $5 = 1000 credits
              </div>
              {dollars && parseFloat(dollars) < MIN_DOLLARS && (
                <div className="mt-2 text-xs text-red-600">
                  Minimum purchase amount is ${MIN_DOLLARS}
                </div>
              )}
            </div>

            <div className="bg-blue-50 rounded-md p-4">
              <div className="text-sm text-blue-800">
                <span className="font-medium">Limited Time Offer:</span> Get extra 1000 free credits with your first purchase!
              </div>
            </div>

            {/* <button 
              className={`w-full py-2 px-4 rounded-md transition-colors duration-200 font-medium ${
                calculatedCredits > 0 
                  ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                  : 'bg-gray-200 text-gray-500 cursor-not-allowed'
              }`}
              disabled={calculatedCredits === 0}
            >
              Purchase Credits
            </button> */}
            <RazorpayCheckout />
            {/* <PaymentStatus /> */}
          </div>
        </div>
      </div>

      {/* Usage Alert */}
      {userDetails?.credits < 100 && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-md">
          <div className="flex items-center">
            <div className="text-yellow-800">
              Your credit balance is running low. Consider purchasing more credits to ensure uninterrupted service.
            </div>
          </div>
        </div>
      )}

      {/* Billing History Card */}
      {/* <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <h2 className="text-xl font-semibold text-gray-800">Billing History</h2>
          <div className="mt-4 overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b border-gray-200">
                  <th className="text-left py-3 px-4 text-sm font-medium text-gray-700">Date</th>
                  <th className="text-left py-3 px-4 text-sm font-medium text-gray-700">Amount</th>
                  <th className="text-left py-3 px-4 text-sm font-medium text-gray-700">Credits</th>
                  <th className="text-left py-3 px-4 text-sm font-medium text-gray-700">Status</th>
                </tr>
              </thead>
              <tbody>
                {billingHistory.map((transaction) => (
                  <tr key={transaction.id} className="border-b border-gray-200">
                    <td className="py-3 px-4 text-sm text-gray-500">{transaction.date}</td>
                    <td className="py-3 px-4 text-sm text-gray-500">${transaction.amount.toFixed(2)}</td>
                    <td className="py-3 px-4 text-sm text-gray-500">{transaction.credits}</td>
                    <td className="py-3 px-4">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        {transaction.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default CreditCalculator;