import { useState } from "react";
import { X } from "lucide-react";

// export default function PromoBanner() {
//   const [visible, setVisible] = useState(true);

//   return (
//     <>
//       {visible && (
//         <div className="w-full bg-blue-600 text-white px-3 py-1.5 flex items-center justify-between text-xs sm:text-sm font-medium">
//           <p className="truncate flex-1 text-center pr-2">
//             🚀 Earn 1000 free credits to avail advanced features! 
//             <span className="hidden sm:inline"> Join the Waitlist for the PRO version & boost your AI workflow! </span>
//             <span className="hidden sm:inline"><a href="https://datacreatorai.com/pricing" className="text-white underline"> Subscribe to Pro</a></span>
//           </p>
//           <button 
//             onClick={() => setVisible(false)} 
//             className="p-0.5 hover:bg-blue-700 rounded"
//             aria-label="Close banner"
//           >
//             <X className="w-4 h-4" />
//           </button>
//         </div>
//       )}
//     </>
//   );
// }

export default function PromoBanner() {
  const [visible, setVisible] = useState(true);

  return (
    <>
      {visible && (
        <div className="w-full bg-blue-600 text-white px-3 py-1.5 flex items-center justify-between text-xs sm:text-sm font-medium">
          {/* Mobile View */}
          <p className="sm:hidden truncate flex-1 text-center pr-2">
            🚀 Preview data for free! Get started with just $5 to enable downloads and receive 1,000 free credits on your first purchase!
            <a href="/creditinfo" className="text-white underline"> Add Credits Now</a>
          </p>

          {/* Desktop View */}
          <p className="hidden sm:flex truncate flex-1 justify-center pr-2">
            🚀 Preview data for free! Get started with just $5 to enable downloads. 
               Receive 1,000 free credits on your first purchase!
            <a href="/creditinfo" className="text-white underline ml-1"> Add Credits Now</a>
          </p>

          <button 
            onClick={() => setVisible(false)} 
            className="p-0.5 hover:bg-blue-700 rounded"
            aria-label="Close banner"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      )}
    </>
  );
}