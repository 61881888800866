import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { TextField, Button, Box, Grid, CircularProgress, MenuItem, Select, FormControl, 
  InputLabel, Card, CardContent, Container, IconButton, Typography, 
  Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import GridComponent from './UpdatableGrid';
import { toast, ToastContainer } from 'react-toastify';
import { AttachFile, Close } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver';
import PopupFormComponent from './EvaluationForm';
import { API_URL } from './constants';
import TermsDialog from './TermsandCondition';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import FeedbackPopup from './FeedbackPopup';
import PromptAssistantModal from './PromptAssistant';
import {callPythonAPI, getImagesFromServer} from './APICalls';
import { ChevronDownIcon, PaperclipIcon, XIcon, ArrowLeftIcon, ArrowRightIcon, ArrowBigUp } from 'lucide-react';
import ConfirmOrderPopup from './OrderPopup';
import RequestReviewPopup from './RequestReviewPopup';
import ConfirmDownload from './ConfirmDownload';
import ChatWidget from './ContactChat';
import ImageGallery from './ImageGallery';
import GenerateConfirmation from './CreditInfoInstant';
import { useUserDetails } from './UserDetailsContext';
import ColumnPopup from "./ColumnPopup";

const Home = ({user}) => {
  const [prompt, setPrompt] = useState('');
  const [selectedOption, setSelectedOption] = useState('custom');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [showGrid, setShowGrid] = useState(false);
  const [number, setNumber] = useState(100);
  const [loadMoreNumber, setLoadMoreNumber] = useState(0);
  const [temperature, setTemperature] = useState(1);
  const [showSpinner, setshowSpinner] = useState(false);
  const [columnNames, setColumnNames] = useState('');
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);

  const [isDisabled, setIsDisabled] = useState(true);
  const [receivedCount, setReceivedCount] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  // To animate to the grid.
  const [animate, setAnimate] = useState(false);
  const [showColNames, setShowColNames] = useState(true);
  // const gridRef = useRef(null);

  const [showResults, setShowResults] = useState(false);
  // const [isLoadMoreClicked, setIsLoadMoreClicked] = useState(false);
  const containerRef = useRef(null);
  const [loadedNumUntilNow, setloadedNumUntilNow] = useState(number);
  const [totalRequiredNumber, setTotalRequiredNumber] = useState(0);
  
  const [isOpenSource, setIsOpenSource] = useState(true);
  var openSourceModels = ["llama-3.3-70b-versatile", "llama-3.2-3b-preview", "Gemini-1.5-Pro", "Deepseek-R1-Llama-70B", "Qwen2.5-72B-Instruct", "Meta-Llama-3.1-405B-Instruct"];
  // "Gemini-2.0-Flash-exp"
  // "Meta-Llama-3.1-8B-Instruct", "Meta-Llama-3.1-70B-Instruct", "Meta-Llama-3.3-70B-Instruct"
  const [selectedModel, setSelectedModel] = useState("llama-3.3-70b-versatile")
  const closedSourceModels = ["Gemini-1.5-Flash", "Gemini-2.0-Flash-exp"];
  const models = isOpenSource ? openSourceModels : closedSourceModels;
  // const [models, setModels] = useState(openSourceModels);
  const [isModelDisabled, setIsModelDisabled] = useState(false);
  const [runTour, setRunTour] = useState(true);
  const [isGoNowButtonDisabled, setIsGoNowButtonDisabled] = useState(false);
  const [isShowImage, setShowImages] = useState(true);
  const [imageData, setImageData] = useState([]);

  // Select Purpose
  var purposes = ["Select", "AI Model Finetuning", "Data Analysis", "ML Model Training", "Recommendation Systems", "Testing", "Just Exploring"];
  const [selectedPurpose, setSelectedPurpose] = useState("Select");
  const [isConfirmOrderPopup, setIsConfirmOrderPopup] = useState(false);

  // Prompt Assistant
  const [promptAssistant, setPromptAssistant] = useState('');
  const [ispromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [isRequestReview, setIsRequestReview] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isConfirmInstantOpen, setIsConfirmInstantOpen] = useState(false);
  const [isColumnPopupOpen, setIsColumnPopupOpen] = useState(false);
  
  const {userDetails, setUserDetails} = useUserDetails();


  const steps = [
    {
      target: '#promptLabel',
      content: (
      <div className="text-left">
      <p>
        In the prompt field, describe the data you want in detail to get the most relevant results. Below are examples of a simple and a detailed prompt:
      </p>
      <ul className="list-disc list-inside">
        <li>
          <strong>Simple Prompt Example: </strong>
          <em>"Generate movie reviews for sentiment analysis."</em>
        </li>
        <li>
          <strong>Detailed Prompt Example: </strong>
          <em>
            "Generate movie reviews about the latest action and drama films, labeled as 
            'positive,' 'negative,' or 'neutral.' Include short and informal language, common in social media 
            posts, with a maximum length of 240 characters for each review."
          </em>
        </li>
      </ul>
    </div>
    ),
    disableBeacon: true,
    },
    // {
    //   target: '#task',
    //   content: 
    //   (
    //     <div className="text-left">
    //       <p>
    //         You can select the type of task you would like to generate data for. Here are the tasks we currently offer:
    //       </p>
    //       <ul className="list-disc list-inside">
    //         <li><strong>Text Classification: </strong>Classify text data into predefined categories. 
    //           <em> (e.g., "Classify customer reviews as positive, neutral, or negative.")</em>
    //         </li>
    //         <li><strong>Question Answering: </strong>Generate questions and corresponding answers for various topics. 
    //           <em> (e.g., "Provide questions and answers based on Wikipedia articles about world history.")</em>
    //         </li>
    //         <li><strong>Instruction Tuning: </strong>Create datasets that train models to follow instructions efficiently.
    //           <em> (e.g., "Generate Instructions to correct grammatical errors in input sentences.")</em>
    //         </li>
    //         <li><strong>Custom: </strong>When your usecase does not match any other usecase, please select custom.
    //           <em> (e.g., "Data for Recommendation systems, Named Entity Recognition etc.,")</em>
    //         </li>
    //       </ul>
    //     </div>
    //   ),
    // },
    {
      target: '#language',
      content: (
        <div className="text-left">
          <p>
            Pick the language in which you want the data to be generated. For now, English is the default, but the Indian languages Hindi and Telugu are also available. 
            If you want a specific language that is not available, please contact us @ <a href="mailto:team@datacreatorai.com" className="text-blue-500">team@datacreatorai.com</a>.
          </p>
        </div>
      )
    },
    {
      target: "#purposeDropdown",
      content: (
        <div className="text-left">
          <p>
            Select the purpose of your data generation. This will help us better customize our features.
          </p>
        </div>
      )
    },
    // {
    //   target: '#modelDropdown',
    //   content: (
    //     <div className="text-left">
    //       <p>
    //         Select a model that will be used to generate your dataset. Gemini-2.0 will be used by default.
    //       </p>
    //     </div>
    //   )
    // },
    {
      target: '#number',
      content: (
      <div className = "text-left">
        <p>
        Specify the minimum number of data samples you would like to generate. The default number 
        is 100 but you can generate a dataset with upto 1000 points at once.
        Click on Order for Later if you need customized datasets with more data points.
        {/* Join the waiting list to our premium version for faster generation of datasets upto 1000 samples at once.
        <a href="https://datacreatorai.com/pricing" className="text-blue-500"> Switch to Pro</a> */}
        </p>
      </div>
      )
    },
    {
      target: '#columnNames',
      content: 
      (<div className="text-left">
      <p>
        Here, you can specify upto 10 custom column names for your data. Use comma-separated names like 
        <em> Column1, Column2, Column3.</em> Please enter names that are relevant to your prompt.
        {/* Join the waiting list to our premium version to generate data with unlimited
        number of columns.<a href="https://datacreatorai.com/pricing" className="text-blue-500"> Switch to Pro</a> */}
      </p>
      <br />
      {/* <ul className="list-disc list-inside">
        <li><strong>Text Classification: </strong><em>Text, Label</em></li>
        <li><strong>Question Answering: </strong><em>Question, Answer</em></li>
        <li><strong>Instruction Tuning: </strong><em>Instruction, Input, Output</em></li>
      </ul> */}
      </div>)
    },
    {
      target: '#fileupload',
      content: (
        <div className='text-left'>
          <p>
          You can augment your existing datasets by uploading your data as CSV Files. 
          The current file size limit is 256 KB. If you need larger file uploads, please place a custom order.
          </p>
        </div>
      )
    },
    // {
    //   target: '#go',
    //   content: 'Click here to start generating your data!'
    // },
    {
      target: '#later',
      content: (
        <div className='text-left'>
          <p>
            Custom Data Orders are a premium, paid offering that goes beyond the features available in our Free and Premium plans. Submit your requirements, and we will get back to you with a special discounted rate.
            {/* Learn more about our pricing and plans here:  <a href="https://datacreatorai.com/pricing" className="text-blue-500">Switch to Pro</a>.   */}
          </p>
        </div>
      )
    }
  ];

  const handleSave = (columns) => {
    console.log("Saved Data:", JSON.stringify(columns, null, 2));
    setIsColumnPopupOpen(false); // Close popup after saving
  };

  const handleOpenPopup = () => {
    console.log("Button clicked!"); // Debug log
    setIsColumnPopupOpen(true);
  };

  const handleUpdate = async (newValue) => {
    // console.log("New Value:" + newValue);
    setPromptAssistant(newValue);
  }

  const showConfirmOrderPopup = () => {
    if(!validateInputs(true)){
      return;
    }
    setIsConfirmOrderPopup(true);
  }

  const handleOrderForLater = async () => {
    if(!validateInputs(true)){
      return;
    }

    const params = {
      userInput : prompt,
      colNames: columnNames
    };
    
    setOrderLoading(true);
    if(uploadedFile) {
      setPromptAssistant("");
      setOrderLoading(false);
      await handleLaterClick();
      return;
    }

    const response = await callPythonAPI(params);
    
    if (response.success) {
      // console.log(response.result);
      setPromptAssistant(response.result);
      setIsPromptModalOpen(true);
      setOrderLoading(false);
    } else {
      // setError(response.error);
      setPromptAssistant(prompt);
      setIsPromptModalOpen(true);
      setOrderLoading(false);
    }
    // alert("Prompt Clicked!")
  };

  const handleProceedOrderPopup = async() => {
    setIsConfirmOrderPopup(false);
    await handleLaterClick("");
  };

  // const handleSubmitOrderLater = async () => {

  // }

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setRunTour(true);
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRunTour(false);
    }
  };
  
  // const handleJoyrideCallback = (data) => {
  //   const { status } = data;
  //   if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
  //     setRunTour(false);
  //   }
  // };

  const handleLaterClick = async(updatedPrompt) => {
    if (validateInputs(true)) {
      const formData = new FormData();
      formData.append('prompt', prompt);
      // console.log("Updated Prompt while posting order:" + promptAssistant)
      formData.append('updatedPrompt', updatedPrompt);
      formData.append('usecase', selectedOption);
      formData.append('number', number);
      formData.append('language', selectedLanguage);
      formData.append('purpose', selectedPurpose);
      formData.append('user_id', user.uid);
      formData.append('include_search_results', isChecked);
      formData.append('temperature', temperature);
      formData.append('model', selectedModel);
      formData.append('is_open_source', isOpenSource);
      formData.append('column_names', columnNames);

      if (uploadedFile) {
        formData.append('file', uploadedFile);
      }

      try {
        const response = await fetch(`${API_URL}/api/order-for-later`, {
          method: 'POST',
          // headers: { 'Content-Type': 'application/json' }, // Uncomment if sending JSON
          body: formData
        });
      
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      
        const data = await response.json(); // Assuming the server responds with JSON
        toast.info(data.datacreatoraimsg); // Show the message from the server
      } catch (error) {
        console.error('Error submitting feedback:', error);
        // alert('Error submitting feedback');
      }
      finally {
        setPromptAssistant("");
      }
    }
  }

  // const startTour = () => {
  //   setRunTour(true);
  // };

  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 256 * 1024) {
        toast.error('File size exceeds 256 KB limit. Please contact us or place a custom order for larger file uploads.');
        return;
      }
      setFileName(file.name);
      setUploadedFile(file);
      setLoading(false);
      // setModels(["Gemini-1.5-Flash"]);
      setSelectedModel("Gemini-1.5-Pro");
      // setIsOpenSource(false);
      // setIsModelDisabled(true);
      toast.info("File Uploaded Successfully.")
    }
    try {
      const headers = await readCSVHeader(file);
      setColumnNames(headers.join(', '));
    } catch (error) {
      console.error('Error reading CSV header:', error);
      toast.error('Failed to read column names from the CSV file.');
    }
   };

   const handleRemoveFile = () => {
    setFileName('');
    setUploadedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const validateInputs = (isOrderLater) => {
    if (prompt.trim().length === 0 || prompt.split(' ').length < 3) {
      toast.error('Please enter a description with at least 3 words.');
      return false;
    }

    if (selectedOption.trim().length === 0) {
      toast.error('Please select a task.');
      return false;
    }

    if(!isOrderLater) {
      if (!isNaN(number) && Number(number) > 1000) {
        toast.error('We support only 1000 data points at a time. Please place a custom order for specific requirements.');
        return false;
      }
    }

    if (isNaN(number) || Number(number) < 0) {
      toast.error('Invalid Number of Records! Please enter a valid number.');
      return false;
    }

    // selectedOption === 'custom' && 
    if(columnNames === ""){
      toast.error("Please mention your preferred column names as comma separated strings or upload a CSV file with sample data.")
      return false;
    }

    if(selectedPurpose === "Select") {
      toast.error("Please select the purpose of your data generation.")
      return false;
    }

    var splitColNames = formatColumnNames(columnNames).split(', ');
    if(splitColNames.some(column => column.trim() === '')){
      toast.error("Column Names cannot be empty. Please check your column names for blank spaces or extra commas.");
      return false;
    }

    if(!isOrderLater) {
      if(splitColNames.length > 10) {
        toast.error("We allow only 10 columns for now. Please place a custom order to generate datasets with more columns.");
        return false;
      }
    }

    return true;
  };

  const convertJsonStringToArray = (jsonString) => {
    // Initialize an empty array to store the parsed JSON objects.
    let results = [];
  
    // Buffer to accumulate characters until a full JSON object is detected.
    let buffer = "";
  
    // Variable to track the current level of nested curly braces.
    let depth = 0;
  
    // Flag to indicate whether we're inside a string literal (inside quotes).
    let inString = false;
  
    // Flag to determine if the next character should be escaped.
    let escapeNext = false;
  
    // Loop through each character in the input string.
    for (let i = 0; i < jsonString.length; i++) {
      // Get the current character.
      const char = jsonString[i];
  
      // Toggle the inString flag if we encounter a double quote not preceded by an escape.
      if (char === '"' && !escapeNext) {
        inString = !inString;
      }
  
      // Only modify the depth count if we're not inside a string.
      if (!inString) {
        // Increase depth when encountering an opening brace.
        if (char === '{') depth++;
        // Decrease depth when encountering a closing brace.
        if (char === '}') depth--;
      }
  
      // Add the current character to the buffer.
      buffer += char;
  
      // Check if we've closed all opened braces and the buffer looks like a JSON object.
      if (depth === 0 && buffer.trim().startsWith('{') && buffer.trim().endsWith('}')) {
        try {
          // Parse the buffer as a JSON object and add it to the results array.
          results.push(JSON.parse(buffer));
        } catch (error) {
          // If parsing fails, log an error along with the problematic JSON snippet.
          console.error("Error parsing JSON:", buffer);
        }
        // Reset the buffer to start accumulating the next JSON object.
        buffer = "";
      }
  
      // Update the escape flag: If current char is a backslash and we're not already escaping, mark escapeNext as true.
      escapeNext = char === '\\' && !escapeNext;
    }
  
    // Return the array containing all successfully parsed JSON objects.
    return results;
  };
  

//  const convertJsonStringToArray = (jsonString) => {
//     try {
//       // Directly parse if the entire string is a valid JSON array
//       const parsedData = JSON.parse(jsonString);
//       if (Array.isArray(parsedData)) {
//         return parsedData;
//       }
//     } catch (e) {
//       // Proceed with regex-based extraction if direct parsing fails
//     }
  
//     // Use a more refined regex to extract JSON objects
//     const jsonRegex = /{(?:[^{}]|(?:\{[^{}]*\}))*}/gs; // 's' flag allows newlines inside JSON
//     var jsonObjects = jsonString.match(jsonRegex);
  
//     if (jsonObjects === null) {
//       return [];
//     }
  
//     return jsonObjects.map(json => {
//       try {
//         const cleanedJson = json.replace(/\\n/g, ''); // Handle escaped newlines
//         return JSON.parse(cleanedJson);
//       } catch (error) {
//         return null; // Skip invalid JSON
//       }
//     }).filter(obj => obj !== null);
//   };  

  // const convertJsonStringToArray = (jsonString) => {
  //   // Use a regular expression to match JSON-like objects, including those with newlines
  //   const jsonRegex = /{(?:[^{}]|(?:\{[^{}]*\}))*}/g;
  //   var jsonObjects = jsonString.match(jsonRegex);
    
  //   if(jsonObjects === null) {
  //     // console.log(jsonString);
  //     return [];
  //   };
  
  //   // Parse each JSON object and create an array
  //   return jsonObjects.map(json => {
  //     try {
  //       // Replace escaped newlines with actual newlines
  //       const cleanedJson = json.replace(/\\n/g, '');
  //       return JSON.parse(cleanedJson);
  //     } catch (error) {
  //       // console.log('Error parsing JSON object:', json);
  //       // console.log('Error details:', error);
  //       return null; // Return null for objects that couldn't be parsed
  //     }
  //   }).filter(obj => obj !== null); // Remove any null entries
  // };

  // const convertJsonStringToArray = (jsonString) => {
  //   try {
  //     // If it's a single JSON object
  //     return [JSON.parse(jsonString)];
  //   } catch (e) {
  //     try {
  //       // If it's multiple JSON objects separated by newlines
  //       return jsonString
  //         .split('\n')
  //         .filter(line => line.trim())
  //         .map(line => JSON.parse(line));
  //     } catch (error) {
  //       console.error('Error parsing JSON:', error);
  //       return [];
  //     }
  //   }
  // };

  useEffect(() => {
    if (userDetails?.credits > 0) {
        setDialogOpen(false); // Close the popup when credits are available
    }
  }, [userDetails?.credits]);

  function getFieldNames(data) {
    if (!Array.isArray(data) || data.length === 0) {
      console.warn("Provided data is not a valid non-empty array.");
      return [];
    }
    
    // Extract and return the keys from the first object in the data array.
    return Object.keys(data[0]);
  }  

  const formatColumnNames = (colNames) => {
    return colNames.split(',').map(col => col.trim()).join(', ');
  };
   
  const fetchRowData = async (isLoadMoreClicked) => {
    if (!validateInputs(false)) {
      return;
    }

    setLoading(true);
    setshowSpinner(true);
    setIsGoNowButtonDisabled(true);
    setLoadMoreNumber(0);

    if(!isLoadMoreClicked) {
      setRowData([]);
      setIsDisabled(true);
      setReceivedCount(0);
      setTotalRequiredNumber(Number(number));
    }
    else{
     // console.log("Updating Records Number:")
      setLoadMoreNumber(100);
      setTotalRequiredNumber(prevTotal => prevTotal + 100);
    }

    try {
      const formData = new FormData();
      formData.append('prompt', prompt);
      formData.append('usecase', selectedOption);
      if(isLoadMoreClicked){
        formData.append('number', 100);
      }
      else{
        formData.append('number', number);
      }
      formData.append('language', selectedLanguage);
      formData.append('purpose', selectedPurpose);
      formData.append('user_id', user.uid);
      formData.append('include_search_results', true);
      formData.append('temperature', temperature);
      formData.append('model', selectedModel);
      // console.log("Is Open Source:")
      // console.log(isOpenSource);
      formData.append('is_open_source', isOpenSource);

      if (uploadedFile) {
        formData.append('file', uploadedFile);
      }

    // Convert dynamic column names into colDefs
    const generateColDefs = (columnNames) => {
      return columnNames.split(', ').map(column => ({
        field: column, editable: false, resizable: true, sortable: true, wrapText: true, autoHeight: true, flex: 2
      }));
    };

    if(columnNames != "") {
      const formattedColNames = formatColumnNames(columnNames);
      const colDefs = generateColDefs(formattedColNames)
      setColDefs(colDefs);
      formData.append('column_names', formattedColNames);
    }
    else
    {
      if (selectedOption === 'classification') {
        // "degree, major, GPA, subjects, work experience, preferred locations, recommended courses"
          formData.append('column_names', "Text, Label");
          setColDefs([
            {
              field: "Text", editable: true, resizable: true, sortable: true, 
              wrapText: true, 
              autoHeight: true, 
              flex: 4
            },
            { field: "Label", editable: true, flex: 1}
          ]);
        }
      else if (selectedOption === 'generation') {
        setColDefs([{ field: "Text", editable: true, flex: 5 }]);
      }
      else if (selectedOption === 'qanda') {
        formData.append('column_names', "Question, Answer");
        setColDefs([
          {
            field: "Question", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 2
          },
          {
            field: "Answer", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 3
          },
        ]);
      }
      else if(selectedOption === 'insttuning'){
        formData.append('column_names', "Instruction, Input, Output");
        setColDefs([
          {
            field: "Instruction", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 2
          },
          {
            field: "Input", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 2
          },
          {
            field: "Output", editable: true, resizable: true, sortable: true, 
            wrapText: true,
            autoHeight: true, 
            flex: 3
          }]
        )
      }
    }
      
      // const startTime = performance.now();
      // const accessToken = user.accessToken;
      // console.log(API_URL);
      const response = await fetch(`${API_URL}/api/generate-data`, {
        method: 'POST',
        // mode: 'no-cors',
        // headers: {
        //   'Authorization': `Bearer ${accessToken}`,  // Replace with actual token
        // },
        body: formData
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setLoading(true);
      // const results = await response.json();
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setIsDisabled(false);
          setshowSpinner(false);
          setIsGoNowButtonDisabled(false);
          // console.log(receivedCount);
          // console.log(totalRequiredNumber);
          //if(receivedCount >= totalRequiredNumber) {
          setIsPopupOpen(true);
          //}
          // if(rowData.length <= 0){
          //   toast.error("Data could not be loaded. Please try again later!");
          // }
          // else{
          toast.info("Data Loading has been completed. You can now download the data as a CSV file.");
          // }
          // const csvContent = prepareCSVContent();
          // saveToServer(rowData, "csv_generated_")
          break
          // console.log("Row Data Length: ");
          // console.log(rowData.length);
          // if(rowData.length === number) {
          //   toast.info("Data Loading has been completed. You can now download the data as a CSV file.");
          // }
          // else {
          //   toast.error("Data Loading Failed. Please try after some time or change your prompt and inputs.");
          // }
        }
        const chunk = decoder.decode(value);
        const items = JSON.parse(JSON.stringify(chunk));
        // console.log("Items")
        // console.log(items);

          const items_converted = convertJsonStringToArray(items);
          if(items_converted.length == 0) {
            continue
          }
          if("datacreatoraimsg" in items_converted[0]){
              toast.error(items_converted[0].datacreatoraimsg);
              break
          }

          // var fieldNames = getFieldNames(items_converted)
          // setColDefs(generateColDefs(fieldNames));
          setShowGrid(true);
          setLoading(false);
          // prevData.length >= Number(number) ? prevData : 
          setRowData(prevData => [...prevData, ...items_converted]);
          // prevCount + items_converted.length >= Number(number) ? number :
          setReceivedCount(prevCount =>  prevCount + items_converted.length);
      }

      const endTime = performance.now();
      // console.log('It took ' + (endTime - startTime) + ' ms.');

    } catch (error) {
      console.error('Error fetching row data:', error);
      toast.error("An Error occured! Please try again or contact the support team.")
    }
    finally {
        if(!isLoadMoreClicked){
          setLoading(false);
          setShowGrid(true);
          setIsDisabled(false);
          setshowSpinner(false);
          setIsGoNowButtonDisabled(false);
        }
    }
  };

  const handleDropdownChange = (event) => {
    var userSelection = event.target.value
    if(userSelection === "classification"){
      setShowColNames(true);
    }
    else{
      setShowColNames(false);
    }
    setColumnNames("");
    setSelectedOption(userSelection);
    setRowData([]);
    setColDefs([]);
    setShowGrid(false);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  }

  const handlePurposeChange = (event) => {
    setSelectedPurpose(event.target.value);
  }

  const handleImageGeneration = async() => {
    if (validateInputs()) {
      setShowImages(true);
      setImageData(await getImagesFromServer({"prompt": prompt, "number": number}));
      setTimeout(() => {
        containerRef.current?.scrollIntoView({ behavior: 'smooth' });  // Scroll to results section
      }, 100);
    }
  };

  const handleConfirmInstantGeneration = () => {
    if(userDetails?.credits === 0) {
      setIsConfirmInstantOpen(true);
    }
    else{
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    if (validateInputs()) {
      setShowResults(true);
      setIsConfirmInstantOpen(false);
      fetchRowData(false);
      // setTimeout(() => {
      //   containerRef.current?.scrollTo({
      //     left: containerRef.current.scrollWidth / 2,
      //     behavior: 'smooth',
      //   });
      // }, 100); 
      // setTimeout(() => {
      //   if (containerRef.current) {
      //     containerRef.current.style.transform = "translateX(-100vw)";
      //   }
      // }, 100);  // Delay to ensure state updates

      setTimeout(() => {
        containerRef.current?.scrollIntoView({ behavior: 'smooth' });  // Scroll to results section
      }, 100);
    }
  };

  const prepareCSVContent = () => {
    const escapeCSV = (cell) => {
      if (cell == null) return '';
      cell = cell.toString();
      if (cell.includes('"') || cell.includes(',') || cell.includes('\n')) {
        return `"${cell.replace(/"/g, '""')}"`;
      }
      return cell;
    };

    const csvHeader = colDefs.map(col => escapeCSV(col.field)).join(',') + '\n';
    const csvData = rowData.map(row => 
      colDefs.map(col => escapeCSV(row[col.field])).join(',')
    ).join('\n');

    const BOM = '\uFEFF';
    const csvContent = BOM + csvHeader + csvData;
    return csvContent;
  };

  const readCSVHeader = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target.result;
        const firstLine = content.split('\n')[0];
        const headers = firstLine.split(',').map(header => header.trim());
        resolve(headers);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const saveToServer = async (csvData, filename) => {
    // try {
      const numberofCreditsNeeded = rowData.length * 0.1
      const updatedCredits = userDetails?.credits - numberofCreditsNeeded
      setUserDetails((prevDetails) => ({
        ...prevDetails, // Spread the existing userDetails
        credits: prevDetails.credits - numberofCreditsNeeded, // Update only credits
      }));
      
      const response = await fetch(`${API_URL}/api/request-download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: csvData,
          userId: user.uid,
          email: user.email,
          filename: filename,
          credits: updatedCredits
        })
      });
  
      // if (!response.ok) {
      //   // throw new Error('Failed to save file to server');
      //   toast.error("Your download request failed. Please try again or contact us.");
      // }
  
      const result = await response.json();
      return result;
    
    // catch (error) {
    //   console.error('Error saving to server:', error);
    //   throw error;
    // }
  };

  const downloadCSV = async () => {
    setDialogOpen(false); // Close the popup
    // toast.info("Processing your request. Please wait!");
    setIsDisabled(true);
    try {
      // Step 1: Save to Server
      const csvContent = prepareCSVContent();
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'data.csv');
      const response = await saveToServer(csvContent, "csv_downloaded_");
      // toast.info(response.message);
      // if (response.status === 200) {
      //   toast.info(response.message);
      // } else {
      //   // Show failure message from server
      //   // || Failed to process your request at this time.
      //   if(response.status === 403) {
      //     toast.error(response.message);
      //   }
      //   else{
      //     toast.error("We could not process your request at this time. Please contact us or try again later.")
      //   }
      // }
      setIsDisabled(false);
    } catch (error) {
      // Handle unexpected errors
      toast.error('Failed to process your request at this time.');
      console.error('Unexpected error:', error);
    }
  };
  
  const handleButtonClick = () => {
    setIsPopupOpen(true);
  };

  const openRequestReviewPopup = () => {
    setIsRequestReview(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleDownloadClick = () => {
    console.log("Download Click");
    var numberofCreditsNeeded = 0; 
    if(rowData.length > 0) {
      numberofCreditsNeeded = rowData.length * 0.1
      if(userDetails?.credits === 0 || numberofCreditsNeeded > userDetails?.credits) {
        setDialogOpen(true);
      }
      else{
        console.log(userDetails);
        if(userDetails?.credits >= numberofCreditsNeeded) {
          downloadCSV();
        }
      }
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // const handleRequestReview = () => {
  //   toast.success("Thank you for placing your request. We will get back to you within 24 hours.")
  // }

  // const handleProceed = (requirements) => {
  //   console.log("User Requirements:", requirements);
  //   toast.success("Thank you for placing your request. We will get back to you within 24 hours.");
    
  // };

  const handleSubmitEvaluation = async(type, evaluation_instructions) => {
    var final_comments = evaluation_instructions;
    if(type === 'feedback'){
      toast.success("Thank you for submitting your feedback. Click on Request Review if you need any changes.");
    }
    else{
      final_comments = "Review Request:" + final_comments
      setIsRequestReview(false);
      toast.success("Thank you for placing your request. We will get back to you within 24 hours.");
    }
    const formData = new FormData();
    const csvContent = prepareCSVContent();
    formData.append('csvData', csvContent);
    formData.append('userId', user.uid);
    formData.append('evalComments', final_comments);
    formData.append('colNames', columnNames);
    formData.append('userPrompt', prompt);
  
    try {
      const response = await fetch(`${API_URL}/api/evaluate-csv`, {
        method: 'POST',
        body: formData
      });
      // const decoder = new TextDecoder();
      // const reader = response.body.getReader();
      // const { value } = await reader.read();
      // const chunk = decoder.decode(value);
      // const items = JSON.parse(JSON.stringify(chunk));
    } catch (error) {
      toast.error('Error sending CSV to backend:', error);
    }
  };

  const handleBackToForm = () => {
    // setShowResults(false);
    setTimeout(() => {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });  // Scroll to results section
    }, 100);
    // // Slide back to the form
    // setTimeout(() => {
    //   containerRef.current?.scrollTo({ 
    //     top: 0, 
    //     behavior: 'smooth'
    //   });
    // }, 100);
  };

  // const handleSlide = () => {
  //     setShowResults(true);
  //     setTimeout(() => {
  //       containerRef.current?.scrollTo({ 
  //         left: containerRef.current.scrollWidth, 
  //         behavior: 'smooth'
  //       });
  //     }, 100);
  // };

  const handleLoadMore = () => {
    // setIsLoadMoreClicked(true);
    // setShowResults(true);
    setloadedNumUntilNow(loadedNumUntilNow + loadMoreNumber);
    fetchRowData(true);
  };

  const handleSourceToggle = () => {
    if(isOpenSource){
      setIsOpenSource(false);
      setSelectedModel("Gemini-1.5-Flash");
    }
    else{
      setIsOpenSource(true);
      setSelectedModel("Meta-Llama-3.1-405B-Instruct");
    }
  };
  
  return (
    // <div>
    //   <Joyride
    //     steps={steps}
    //     run={run}
    //     continuous={true}
    //     showProgress={true}
    //     showSkipButton={true}
    //     disableBeacon={true}
   // className="flex overflow-x-hidden w-screen"
    //   />
    <div className = "overflow-hidden">
      <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        disableOverlayClose={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: '#e3ffeb',
            backgroundColor: 'white',
            overlayColor: 'rgba(79, 26, 0, 0.4)',
            primaryColor: '#000',
            textColor: '#004a14',
            textAlign: 'left',
            width: 600,
            zIndex: 1000,
          }
        }}
      />
      <div className="flex transition-transform duration-500 ease-in-out" style={{ width: '200vw' }}>
      <div className="flex-shrink-0 w-screen">
        <div className="container mx-auto p-4 max-w-4xl">
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            {/* <button
              onClick={startTour}
              className="float-right mb-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Start Tour
            </button> */}
            {/* <button
                  onClick={handleSlide}
                  className="float-right bg-gray-200 text-gray-700 px-4 py-2 rounded-lg mr-2 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  <ArrowRightIcon className="w-5 h-5" />
            </button> */}
          <div className="mb-6">
          <label id = "promptLabel" htmlFor="prompt" className="block text-sm font-medium text-gray-700 mb-2 p-2">
            Describe the data you want to generate in detail in English*
          </label>
          
          <div className="relative">
            <textarea
              id="prompt"
              required
              rows="5"
              className="w-full p-3 pr-10 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
            <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
              <button
                type="button"
                id="fileupload"
                onClick={() => fileInputRef.current.click()}
                className="text-gray-400 hover:text-gray-600"
              >
                <PaperclipIcon className="w-5 h-5" />
              </button>
            </div>
          </div>

        {fileName && (
          <div className="mt-2 text-sm text-green-600 flex items-center">
            <span>File selected: {fileName}</span>
            <button onClick={handleRemoveFile} className="ml-2 text-red-500 hover:text-red-700">
              <XIcon className="w-4 h-4" />
            </button>
          </div>
        )}
        
        <input
          required
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          className="hidden"
          accept=".csv"
        />
      </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              {/* <div>
                <label id="task" htmlFor="task" className="block text-sm font-medium text-gray-700 mb-1">
                  Select Task*
                </label>
                <div className="relative">
                  <select
                    value={selectedOption}
                    onChange={handleDropdownChange}
                    className="w-full p-2 border border-gray-300 rounded-lg appearance-none focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="custom">Custom</option>
                    <option value="classification">Text Classification</option>
                    <option value="qanda">Question Answering</option>
                    <option value="insttuning">Instruction Tuning</option>
                  </select>
                  <ChevronDownIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                </div>
              </div> */}

              <div>
                <label htmlFor="language" className="block text-sm font-medium text-gray-700 mb-1">
                  Select Language*
                </label>
                <div className="relative">
                  <select
                    id="language"
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    className="w-full p-2 border border-gray-300 rounded-lg appearance-none focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="en">English</option>
                    <option value="hi">Hindi</option>
                    <option value="te">Telugu</option>
                  </select>
                  <ChevronDownIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                </div>
              </div>

              {/* <div>
                <label htmlFor="modelDropdown" className="block text-sm font-medium text-gray-700 mb-1">
                  Select Model
                </label>
                <select
                  id="modelDropdown"
                  value={selectedModel}
                  onChange={handleModelChange}
                  isDisabled={isModelDisabled}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {models.map((model) => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </select>
              </div> */}
              <div>
                <label htmlFor="purposeDropdown" className="block text-sm font-medium text-gray-700 mb-1">
                  Select Purpose*
                </label>
                <select
                  id="purposeDropdown"
                  value={selectedPurpose}
                  onChange={handlePurposeChange}
                  // isDisabled={isModelDisabled}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {purposes.map((purpose) => (
                    <option key={purpose} value={purpose}>
                      {purpose}
                    </option>
                  ))}
                </select>
              </div> 

              <div>
                <label htmlFor="number" className="block text-sm font-medium text-gray-700 mb-1">
                  Min. Number of Results*
                </label>
                <input
                  required
                  type="number"
                  id="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div className="">
            {/* Horizontal Alignment */}
            <div className="flex items-center space-x-6 w-full">
              {/* Temperature */}
              {/* <div className="flex-1">
                <label htmlFor="temperature" className="block text-sm font-medium text-gray-700 mb-1">
                  Temperature* (0-2)
                </label>
                <div className="flex items-center">
                  <input
                    type="range"
                    id="temperature"
                    min="0"
                    max="2"
                    step="0.1"
                    value={temperature}
                    onChange={(e) => setTemperature(parseFloat(e.target.value))}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  />
                  <span className="ml-4 text-sm text-gray-700">{temperature.toFixed(1)}</span>
                </div>
              </div> */}

              {/* Toggle */}
              {/* <div className="flex-1">
                <div className="flex items-center space-x-2">
                  <label htmlFor="sourceToggle" className="text-sm font-medium text-gray-700">
                    Open Source Models
                  </label>
                  <div
                    onClick={handleSourceToggle}
                    className={`relative inline-block w-16 h-8 rounded-full transition-all duration-300 ease-in-out ${
                      isOpenSource ? "bg-green-500" : "bg-gray-300"
                    }`}
                  >
                    <div
                      className={`absolute top-1 left-1 w-6 h-6 bg-white rounded-full transition-all duration-300 ease-in-out transform ${
                        isOpenSource ? "translate-x-8" : ""
                      }`}
                    ></div>
                  </div>
                </div>
              </div> */}

              {/* Models Dropdown */}
              {/* <div className="flex-1">
                <label htmlFor="modelDropdown" className="block text-sm font-medium text-gray-700">
                  Select Model
                </label>
                <select
                  id="modelDropdown"
                  value={selectedModel}
                  onChange={handleModelChange}
                  isDisabled={isModelDisabled}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {models.map((model) => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
          </div>

    <div className="flex items-center justify-between py-2">
      {/* <div className="mb-4"> */}
        {/* <label htmlFor="columnNamesLabel" className="block text-sm font-medium text-gray-700 mb-2">
          Enter upto 5 relevant column names*
        </label> */}
        <input
          type="text"
          id="columnNames"
          value={columnNames}
          placeholder='Enter comma separated column names to have custom columns.*'
          onChange={(e) => setColumnNames(e.target.value)}
          className="w-full mr-4 p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        />
      {/* </div> */}
      <div className="flex gap-3 flex-shrink-0">
        <button
          id="go"
          onClick={handleConfirmInstantGeneration}
          disabled={isGoNowButtonDisabled}
          className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
        >
          Go Now
        </button>
        {/* <button
          id="columnPopup"
          onClick={handleOpenPopup}
          disabled={isColumnPopupOpen}
          className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
        >
          Add Columns
        </button> */}
        {/* <button
          id="go"
          onClick={handleImageGeneration}
          disabled={isGoNowButtonDisabled}
          className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
        >
          Generate Images
        </button> */}
        <button
          id="later"
          onClick={showConfirmOrderPopup}
          className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        >
          {orderLoading ? (
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0"></path>
            </svg>
          ) : 'Custom Order'}
        </button>
      </div>
      <ChatWidget isOpen={isChatOpen} setIsOpen={setIsChatOpen} />
    </div>
          </div>
      </div>
      <div>
      {showResults && (
          <div ref={containerRef} className="container mx-auto p-4">
            {/* {loading && (
              <div className="flex justify-center items-center h-32">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            )} */}

            {(
              <div className="bg-white rounded-lg shadow-md p-4">
                <div className="flex justify-between items-center mb-4">
                  <button
                    onClick={handleBackToForm} disabled
                    className="mr-4 text-blue-600 hover:text-blue-800 focus:outline-none"
                  >
                    {/* <ArrowBigUp className="w-6 h-6" /> */}
                  </button>
                  <span className="text-md text-gray-600 flex items-center">
                  {/* + Number(loadMoreNumber) */}
                    {receivedCount} of {totalRequiredNumber} rows loaded
                    {showSpinner && (
                      <div className="ml-2 animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"></div>
                    )}
                  </span>
                  <div>
                    {/* <button
                      onClick={handleLoadMore}
                      disabled={isDisabled}
                      className="mr-2 px-4 py-2 bg-white text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
                    >
                     Load 100 More
                    </button> */}
                    <button
                      onClick={openRequestReviewPopup}
                      disabled={isDisabled}
                      className="mr-2 px-4 py-2 bg-blue-600 text-white border border-blue-600 rounded-lg"
                    >
                      Request Review
                    </button>
                    <button
                      onClick={handleDownloadClick}
                      disabled={isDisabled}
                      className="px-4 py-2 bg-white text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
                    >
                      Download Dataset
                    </button>
                  </div>
                </div>
                <div>
                  {<GridComponent rowData={rowData} colDefs={colDefs} />}
                </div>
                <PopupFormComponent
                  open={isPopupOpen}
                  onClose={handleClosePopup}
                  onSubmit={handleSubmitEvaluation}
                />

                {/* <PromptAssistantModal
                  isOpen={ispromptModalOpen}
                  onClose={() => setIsPromptModalOpen(false)}
                  promptText={promptAssistant}
                  setPromptText={setPromptAssistant}
                  onConfirm={handleConfirm}
                /> */}
                {/* <TermsDialog open={dialogOpen} handleClose={handleDialogClose} handleAgree={downloadCSV} /> */}
                <ConfirmDownload open = {dialogOpen} credits={userDetails?.credits} onCancel={handleDialogClose} onProceed={downloadCSV} />
                
              </div>
            )}
          </div>
      )}
      {/* {isShowImage && 
         <div ref={containerRef} className="container mx-auto p-4">
            <ImageGallery images={imageData} />
         </div>
      } */}
      </div>
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
      <ConfirmOrderPopup 
        isOpen={isConfirmOrderPopup} 
        onClose={() => setIsConfirmOrderPopup(false)} 
        onConfirm={handleProceedOrderPopup}
      />
      <GenerateConfirmation 
      openModal={isConfirmInstantOpen}  
      userCredits={0}
      setShowModal={() => setIsConfirmInstantOpen(false)}
      onGenerate={handleSubmit}
      />
      <RequestReviewPopup 
        open={isRequestReview} 
        onClose={() => setIsRequestReview(false)} 
        onProceed={handleSubmitEvaluation}
      />
      {/* <PromptAssistantModal 
          open={ispromptModalOpen}
          onClose={handlePromptAssistant}
          promptAssistant={promptAssistant}
          onUpdate={handleUpdate}
      /> */}
      {/* <FeedbackModal 
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="How was your purchase experience?"
        subtitle="Help us improve our checkout process"
      /> */}
      {/* <FeedbackPopup /> */}
      {/* <div className="flex justify-end">
        <button
          onClick={handleSlide}
          className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        >
          <ArrowRightIcon className="w-5 h-5" />
        </button>
      </div> */}
    </div>
   </div>
  );
};

export default Home;