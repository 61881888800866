export const API_URL = "https://synthetic-backend-prod-mmlcpe4eca-em.a.run.app"
// // "https://synthetic-backend-test-111535837563.asia-south1.run.app"

// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"
// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"

export const SIGNIN_BENEFITS = [
  "Generate and preview high-quality text and numeric datasets before downloading.",
  "Upload CSV files to augment and balance real datasets with synthetic data.",
  "Place custom orders for datasets specific to your use case and receive them within 24-48 hours.",
  // "Integrate your own knowledge sources (PDF, JPG) to ensure domain relevance.",
  "Get expert-reviewed data with flexible and customized pricing for high-quality assurance.",
  "Generate data in multiple Indian and foreign languages.",
  "Contribute to our community datasets and become a paid reviewer."
  // "Pay-per-review model for data evaluation by experts."
];

// "http://localhost:5000"
// https://synthetic-backend-mmlcpe4eca-el.a.run.app
// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"
// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"
// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"
// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

//  https://synthetic-backend-mmlcpe4eca-em.a.run.app
// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "http://localhost:5000"

// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-el.a.run.app";