import { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";

export default function RequestReviewPopup({ open, onClose, onProceed }) {
  const [requirements, setRequirements] = useState("");

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className="text-xl font-semibold">Request a Human Review</DialogTitle>
      <DialogContent className="text-gray-700 space-y-4">
        <p>Get a human expert to review and refine your generated dataset as per your requirements.</p>
        <p className="font-medium">Estimated Pricing: <span className="text-blue-600">Dynamic (Based on the size and effort needed)</span></p>
        <p className="font-medium">Estimated Response Time: <span className="text-green-600">Within 24 hours</span></p>

        <TextField
          label="Describe the changes to be made in detail"
          multiline
          rows={3}
          variant="outlined"
          fullWidth
          value={requirements}
          onChange={(e) => setRequirements(e.target.value)}
        />
      </DialogContent>
      <DialogActions className="p-4">
        <button className="px-4 py-2 bg-gray-200 rounded-lg" onClick={onClose}>Cancel</button>
        <button 
          className="px-4 py-2 bg-blue-600 text-white rounded-lg"
          onClick={() => onProceed("review", requirements)}
        >
          Proceed
        </button>
      </DialogActions>
    </Dialog>
  );
}
