import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";

export default function GenerateConfirmation({ openModal, userCredits, setShowModal, onGenerate }) {
  const navigate = useNavigate();

  const onBuyCredits = () => {
    navigate("/creditinfo");
  };

  return (
    <div className="text-center">
      {openModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-2xl shadow-lg w-200 text-center relative">
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={() => setShowModal(false)}
            >
              <X size={20} />
            </button>

            <h2 className="text-xl font-semibold">Confirm Data Generation</h2>
            <p className="mt-3 text-gray-600">
              You are low on credits to download the data. 
            </p>
            <p className="mt-3 text-gray-600">How would you like to proceed?</p>

            <div className="mt-6 flex justify-between gap-3 w-auto px-3 py-2 text-sm">
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition w-1/2 text-center"
              onClick={onGenerate}
            >
              Preview the Data
            </button>
            <button
              className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition w-1/2 text-center"
              onClick={onBuyCredits}
            >
              Buy Credits
            </button>
          </div>
          </div>
        </div>
      )}
    </div>
  );
}