import { useState } from "react";
import { Dialog } from "@headlessui/react";

export default function ConfirmOrderPopup({ isOpen, onClose, onConfirm }) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="fixed inset-0 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[500px]">
        <Dialog.Title className="text-lg font-semibold">Confirm Custom Order</Dialog.Title>
        <Dialog.Description className="mt-2 text-gray-600">
            Custom Orders are paid and may involve human review. You will receive a customized pricing quote based on your request. 
            Do you want to proceed?
            {/* You can optionally join our waitlist for Pro for more advanced data generation. 
            Check out our plans <a href="https://datacreatorai.com/pricing" className="text-blue-500">here.</a> */}
           <p>By placing this order, you agree to our <a href="/terms-and-privacy" className="text-blue-500">Terms and Conditions</a></p>
        </Dialog.Description>
        <div className="mt-4 flex justify-end gap-2">
          <button onClick={onClose} className="px-4 py-2 bg-gray-200 rounded-md">Cancel</button>
          <button onClick={onConfirm} className="px-4 py-2 bg-blue-600 text-white rounded-md">Proceed</button>
        </div>
      </div>
    </Dialog>
  );
}
